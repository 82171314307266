import React, { Children, cloneElement } from "react";
import LoadingIndicator from "../react-chatbot-kit/components/Loader/Loader.tsx";
import { createCustomMessage } from "../react-chatbot-kit/index.ts";

const post = async (url, body) =>
  fetch(url, {
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
    method: "POST",
  }).then((resp) => {
    if (!resp.ok) throw new Error("API response invalid");
    return resp.json();
  });

function ActionProvider(chatbotJson) {
  const reactElement = ({ state, setState, children }) => {
    const updateChatbotState = (message, replace = false, options = []) => {
      setState((prevState) => {
        const messages = [...prevState.messages];
        messages.forEach((message) => {
          if (message.widget) delete message.widget;
          return message;
        });
        if (replace) {
          // Replace the last message (placeholder) with the new message
          messages[messages.length - 1] = message;
        } else {
          // Add new message
          messages.push(message);
        }
        return {
          ...prevState,
          messages,
          options,
        };
      });
    };

    const handleMessage = async (message) => {
      // Show placeholder message
      const placeholderMessage = createCustomMessage(
        <LoadingIndicator />,
        "custom",
        { payload: <LoadingIndicator /> },
      );
      updateChatbotState(placeholderMessage);

      let baseURL = "https://sb-chatbot.fly.dev";
      if (process.env.NODE_ENV === "development")
        baseURL = process.env.BACKEND_OVERRIDE;

      const messageHistory = state.messages.map((message) => ({
        type: message.type,
        message: message.message,
      }));

      try {
        const response = await post(`${baseURL}/chat`, {
          message_history: messageHistory,
          message,
          token: chatbotJson.id,
          referrer: window.location.href,
        });

        const botMessage = createCustomMessage(response.response, "custom", {
          widget: "SuggestedOption",
          payload: response.response,
        });

        // Replace placeholder with actual response
        updateChatbotState(botMessage, true, response.options);
      } catch (error) {
        console.error("Error sending message to backend:", error);

        const errorString =
          "Sorry, there was an error processing your request.";
        const errorMessage = createCustomMessage(errorString, "custom", {
          payload: errorString,
        });
        updateChatbotState(errorMessage, true); // Replace placeholder with error message
      }
    };

    return (
      <div>
        {Children.map(children, (child) => {
          return cloneElement(child, {
            actions: {
              handleMessage,
            },
          });
        })}
      </div>
    );
  };

  reactElement.displayName = "ActionProvider";
  return reactElement;
}

export default ActionProvider;
