import React from "react";
import axios from "axios";
import LoadingIndicator from "./LoadingIndicator";
import { createCustomMessage } from "react-chatbot-kit";

const ActionProvider = ({ setState, children }) => {
  const updateChatbotState = (message, replace = false, options = []) => {
    setState((prevState) => {
      const messages = [...prevState.messages];
      messages.forEach((message) => {
        message.widget && delete message.widget;
        return message;
      });
      if (replace) {
        // Replace the last message (placeholder) with the new message
        messages[messages.length - 1] = message;
      } else {
        // Add new message
        messages.push(message);
      }
      return {
        ...prevState,
        messages: messages,
        options: options,
      };
    });
  };

  const handleMessage = async (message) => {
    // Show placeholder message
    const placeholderMessage = createCustomMessage(
      <LoadingIndicator />,
      "custom",
      {payload: <LoadingIndicator />}
    );
    updateChatbotState(placeholderMessage);

    try {
      const response = await axios.post(`${"/chat"}`, {
        message: message,
        referrer: window.location.href,
      });

      const botMessage = createCustomMessage(response.data.response, "custom", {
        widget: "SuggestedOption",
        payload: response.data.response
      });

      // Replace placeholder with actual response
      updateChatbotState(botMessage, true, response.data.options);
    } catch (error) {
      console.error("Error sending message to backend:", error);

      const errorString = "Sorry, there was an error processing your request.";
      const errorMessage = createCustomMessage(
        errorString,
        "custom",
        {payload: errorString}
      );
      updateChatbotState(errorMessage, true); // Replace placeholder with error message
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleMessage,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
