import axios from "axios";
import { useState } from 'react';
import Chatbot from "./react-chatbot-kit/index.ts";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import config from './chatbot/Config';
import MessageParser from './chatbot/MessageParser';
import ActionProvider from './chatbot/ActionProvider';
import ChatbotCloseButton from "./assets/chatbot-close.svg";
import ChatbotOpenButton from "./assets/chatbot-open.svg";

function App() {
  const [throttle, setThrottle] = useState(0);
  const [showChatbot, setShowChatbot] = useState(true);

  const throttleFunc = (func: Function, delay: integer) => {
    if (Date.now() - throttle <= delay) return false;
    setThrottle(Date.now());
    return func();
  };

  const toggleChatbot = () => {
    setShowChatbot(!showChatbot);
  };

  return (
    <div className="App">
      <header className="App-header">
        {showChatbot &&
          <Fade big>
            <div className="card">
              <div className="chatbot-close-button" onClick={toggleChatbot}>
                <ChatbotCloseButton />
              </div>
              <Chatbot
                headerText='Chat with Simply AI'
                config={config}
                messageParser={MessageParser}
                actionProvider={ActionProvider}
                onTypeCallback={() => throttleFunc(() => { axios.get("/wakeup") }, 2 * 60 * 1000)}
              />
            </div>
          </Fade>
        }
        <Flip left cascade>
          <button
            className="app-chatbot-button"
            onClick={toggleChatbot}
          >
            {showChatbot ?
              <ChatbotCloseButton className="close-chatbot-icon" /> :
              <ChatbotOpenButton />
            }
          </button>
        </Flip>
      </header>
    </div>
  );
}

export default App;
