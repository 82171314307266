import React from 'react';
import App from './App';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import reactChatbotStyles from 'react-chatbot-kit/build/main.css';
import bootstrap from 'bootstrap/dist/css/bootstrap.css';
import styles from './styles.css';

// Set default base URL for Axios
axios.defaults.baseURL = 'https://sb-chatbot.fly.dev';
// Set default headers for Axios
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; // Allow requests from any origin

window.addEventListener("DOMContentLoaded", function (e) {
  const shadowDomEl = document.createElement('div');
  document.body.appendChild(shadowDomEl);

  const shadowRoot = shadowDomEl.attachShadow({ mode: 'open' });
  shadowRoot.adoptedStyleSheets = [bootstrap, reactChatbotStyles, styles];

  const reactRoot = document.createElement('div');
  reactRoot.id = 'chatbot-root';
  shadowRoot.appendChild(reactRoot);

  createRoot(reactRoot).render(
  <React.StrictMode>
    <App />
    </React.StrictMode>
);
});
