// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-chatbot-kit-error {
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
}

.react-chatbot-kit-error-container {
  width: 260px;
}

.react-chatbot-kit-error-header {
  font-size: 1.3rem;
  color: #1d1d1d;
  margin-bottom: 30px;
}

.react-chatbot-kit-error-docs {
  display: block;
  margin: 25px auto;
  color: rgb(56, 104, 139);
  padding: 8px;
  border: 1px solid rgb(40, 152, 236);
  width: 130px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/react-chatbot-kit/components/ChatbotError/ChatbotError.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,wBAAwB;EACxB,YAAY;EACZ,mCAAmC;EACnC,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":[".react-chatbot-kit-error {\n  background-color: #fff;\n  border-radius: 3px;\n  padding: 15px;\n}\n\n.react-chatbot-kit-error-container {\n  width: 260px;\n}\n\n.react-chatbot-kit-error-header {\n  font-size: 1.3rem;\n  color: #1d1d1d;\n  margin-bottom: 30px;\n}\n\n.react-chatbot-kit-error-docs {\n  display: block;\n  margin: 25px auto;\n  color: rgb(56, 104, 139);\n  padding: 8px;\n  border: 1px solid rgb(40, 152, 236);\n  width: 130px;\n  text-align: center;\n  text-decoration: none;\n  font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
var ___CSS_LOADER_STYLE_SHEET___ = new CSSStyleSheet();
___CSS_LOADER_STYLE_SHEET___.replaceSync(___CSS_LOADER_EXPORT___.toString());
export default ___CSS_LOADER_STYLE_SHEET___;
