import React, { StrictMode } from "react";
import App from "./App";
import { createRoot } from "react-dom/client";
import chatStyles from "./react-chatbot-kit/components/Chat/Chat.css";
import chatbotErrorStyles from "./react-chatbot-kit/components/ChatbotError/ChatbotError.css";
import chatbotMessageStyles from "./react-chatbot-kit/components/ChatbotMessage/ChatbotMessage.css";
import userChatMessageStyles from "./react-chatbot-kit/components/UserChatMessage/UserChatMessage.css";
import bootstrap from "bootstrap/dist/css/bootstrap.css";
import styles from "./styles.css";

window.loadChatbot = () => {
  const shadowDomEl = document.createElement("div");
  document.body.appendChild(shadowDomEl);

  const shadowRoot = shadowDomEl.attachShadow({ mode: "open" });
  shadowRoot.adoptedStyleSheets = [
    bootstrap,
    chatStyles,
    chatbotErrorStyles,
    chatbotMessageStyles,
    userChatMessageStyles,
    styles,
  ];

  const reactRoot = document.createElement("div");
  reactRoot.id = "chatbot-root";
  shadowRoot.appendChild(reactRoot);

  let selector = 'script[src="https://chatbot-ui.simplybinary.com/bot.js"]';
  if (process.env.NODE_ENV === "development") {
    console.log("Running in development mode!");
    selector = 'script[src="/bot.js"]';
  }
  const scriptTag = document.querySelector(selector);
  if (!scriptTag) {
    console.error("Could not load Simply Binary AI Chatbot");
    return;
  }

  let chatbotID = scriptTag.getAttribute("token");
  if (process.env.NODE_ENV === "development") {
    chatbotID = process.env.TOKEN_OVERRIDE;
  }
  if (!chatbotID) return;

  let chatbotUrl = new URL(
    `${chatbotID}.json`,
    "https://chatbot.simplybinary.com/chatbots/",
  );
  if (process.env.NODE_ENV === "development") {
    chatbotUrl = new URL(
      `${chatbotID}.json`,
      `${process.env.PORTAL_OVERRIDE}/chatbots/`,
    );
  }
  fetch(chatbotUrl)
    .then((r) => r.json())
    .then((chatbotJson) => {
      // prefetch logo so it appears instantly
      if (chatbotJson?.thumbLogo) {
        const img = new Image();
        img.src = chatbotJson.thumbLogo;
      }
      createRoot(reactRoot).render(
        <StrictMode>
          {chatbotID && chatbotJson?.enabled && (
            <App chatbotJson={chatbotJson} />
          )}
        </StrictMode>,
      );
    });
};

window.addEventListener("DOMContentLoaded", window.loadChatbot);
