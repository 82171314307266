import { StrictMode } from 'react';
import App from './App';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import chatStyles from './react-chatbot-kit/components/Chat/Chat.css';
import chatbotErrorStyles from './react-chatbot-kit/components/ChatbotError/ChatbotError.css';
import chatbotMessageStyles from './react-chatbot-kit/components/ChatbotMessage/ChatbotMessage.css';
import userChatMessageStyles from "./react-chatbot-kit/components/UserChatMessage/UserChatMessage.css"
import bootstrap from 'bootstrap/dist/css/bootstrap.css';
import styles from './styles.css';

// Set default base URL for Axios
axios.defaults.baseURL = 'https://sb-chatbot.fly.dev';
// Set default headers for Axios
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; // Allow requests from any origin

window.addEventListener("DOMContentLoaded", function (e) {
  const shadowDomEl = document.createElement('div');
  document.body.appendChild(shadowDomEl);

  const shadowRoot = shadowDomEl.attachShadow({ mode: 'open' });
  shadowRoot.adoptedStyleSheets = [
    bootstrap,
    chatStyles,
    chatbotErrorStyles,
    chatbotMessageStyles,
    userChatMessageStyles,
    styles
  ];

  const reactRoot = document.createElement('div');
  reactRoot.id = 'chatbot-root';
  shadowRoot.appendChild(reactRoot);

  createRoot(reactRoot).render(
  <StrictMode>
    <App />
    </StrictMode>
);
});
