import Linkify from 'react-linkify';
import Avatar from "./Avatar";

const Message = ({
  message
}) => {
  return (
    <div className="react-chatbot-kit-chat-bot-message-container">
      <Avatar />

      <div className="react-chatbot-kit-chat-bot-message">
        <span><Linkify>{message.message}</Linkify></span>
        <div className="react-chatbot-kit-chat-bot-message-arrow"></div>
      </div>
    </div>
  );
};

export default Message;
