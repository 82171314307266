import { createCustomMessage } from "react-chatbot-kit";
import ActionProvider from "./ActionProvider";
import SimplyBinaryAvatar from "./SimplyBinaryAvatar";
import SuggestedOption from "./SuggestedOption";
import Message from "./Message";

const config = {
  botName: "Simply Binary",
  initialMessages: [createCustomMessage(`Hi. I'm an AI chat bot created by Simply Binary to interact with our website. Ask me questions like "Who founded Simply Binary?" or "What kind of services do you provide?"`, "custom")],
  customStyles: {
    chatButton: {
      backgroundColor: "#64D431",
    },
  },
  widgets: [
    {
      widgetName: "SuggestedOption",
      widgetFunc: (props) => <SuggestedOption {...props} />,
    },
  ],
  customComponents: { botAvatar: (props) => <SimplyBinaryAvatar {...props} /> },
  customMessages: {
    custom: (props) => (
      <Message
        {...props}
        message={props.state.messages.find(
          (msg) => msg.payload === props.payload
        )}
      />
    ),
  },
  state: {},
  actionProvider: ActionProvider,
};

export default config;
