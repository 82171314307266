// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-chatbot-kit-user-chat-message-container {
  display: flex;
  margin: 15px 0;
  justify-content: flex-end;
}

.react-chatbot-kit-user-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 12.5px;
  background-color: #3d4e8d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-chatbot-kit-user-avatar-icon {
  fill: #fff;
  width: 15px;
  height: 15px;
}

.react-chatbot-kit-user-chat-message {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #585858;
  font-weight: medium;
  position: relative;
  text-align: left;
}

.react-chatbot-kit-user-chat-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #f1f1f1;
  position: absolute;
  right: -7px;
  top: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/react-chatbot-kit/components/UserChatMessage/UserChatMessage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,QAAQ;EACR,SAAS;EACT,iCAAiC;EACjC,oCAAoC;EACpC,8BAA8B;EAC9B,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX","sourcesContent":[".react-chatbot-kit-user-chat-message-container {\n  display: flex;\n  margin: 15px 0;\n  justify-content: flex-end;\n}\n\n.react-chatbot-kit-user-avatar-container {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  margin-left: 12.5px;\n  background-color: #3d4e8d;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.react-chatbot-kit-user-avatar-icon {\n  fill: #fff;\n  width: 15px;\n  height: 15px;\n}\n\n.react-chatbot-kit-user-chat-message {\n  background-color: #f1f1f1;\n  padding: 10px;\n  border-radius: 5px;\n  font-size: 0.9rem;\n  color: #585858;\n  font-weight: medium;\n  position: relative;\n  text-align: left;\n}\n\n.react-chatbot-kit-user-chat-message-arrow {\n  width: 0;\n  height: 0;\n  border-top: 8px solid transparent;\n  border-bottom: 8px solid transparent;\n  border-left: 8px solid #f1f1f1;\n  position: absolute;\n  right: -7px;\n  top: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
var ___CSS_LOADER_STYLE_SHEET___ = new CSSStyleSheet();
___CSS_LOADER_STYLE_SHEET___.replaceSync(___CSS_LOADER_EXPORT___.toString());
export default ___CSS_LOADER_STYLE_SHEET___;
