import { createClientMessage } from "../react-chatbot-kit/index.ts";

const SuggestedOption = ({ actionProvider, state, setState }) => {
  const createUserMessage = (text) => {
    const message = createClientMessage(text);
    setState((prevState) => {
      const messages = [...prevState.messages];
      messages.forEach((message) => {
        message.widget && delete message.widget;
        return message;
      });
      return {
        ...prevState,
        messages: [...messages, message],
        options: [],
      };
    });
    actionProvider.handleMessage(text);
  };

  return (
    state?.options?.length !== 0 && (
      <div className="options">
        {" "}
        {state.options.map((option) => (
          <button
            className="suggested-option"
            key={option}
            onClick={() => createUserMessage(option)}
            type="button"
          >
            {option}
          </button>
        ))}
      </div>
    )
  );
};

export default SuggestedOption;
