import React from "react";

const reloadImage = (e) => {
  // only attempt reload once
  e.target.onerror = null;

  // eslint-disable-next-line no-self-assign
  e.target.src = e.target.src;
};

const Avatar = ({ thumbLogo }) => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      {thumbLogo ? (
        <img
          alt="Chatbot logo"
          className="react-chatbot-kit-chat-bot-avatar-img"
          src={thumbLogo}
          onError={reloadImage}
          onLoad={(e) => (e.target.onerror = reloadImage)}
        />
      ) : (
        <div className="react-chatbot-kit-chat-bot-avatar-blank" />
      )}
    </div>
  );
};

export default Avatar;
