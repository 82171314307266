import SBLogo from "../assets/sb-white-logo.svg"

const Avatar = () => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div
        className="react-chatbot-kit-chat-bot-avatar-container"
        style={{ background: "none" }}
      >
        <SBLogo />
      </div>
    </div>
  );
};

export default Avatar;
