import React from "react";
import sbLogo from "../assets/sb-white-logo.svg"

const SimplyBinaryAvatar = () => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div
        className="react-chatbot-kit-chat-bot-avatar-container"
        style={{ background: "none" }}
      >
        <img alt="SimplyBinaryAvatar" src={sbLogo} />
      </div>
    </div>
  );
};

export default SimplyBinaryAvatar;
